<template>
  <tr class="add-row">
    <td></td>
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>

    <template
      v-for="column in columns.filter((colFiltered) => !colFiltered.isHide)"
      :key="column.key"
    >
      <td>
        <div class="table-cell" :style="setWidthColumn(column)">
          <input-text v-if="column.key === 'url'" v-model="url" />
          <input-text v-else-if="column.key === 'anchor'" v-model="anchor" />
          <input-select
            v-else-if="column.key === 'link_status'"
            v-model="link_status"
          />
          <!-- <input-select
            v-else-if="column.key === 'slack_community'"
            v-model="slack_community"
          /> -->
          <input-text
            v-else-if="column.key === 'target_page'"
            v-model="target_page"
          />
          <input-select
            v-else-if="column.key === 'commercial_pages_link'"
            v-model="commercial_pages_link"
          />
          <input-select
            v-else-if="column.key === 'company_name'"
            v-model="company_name"
          />
          <input-text
            v-else-if="column.key === 'comments'"
            v-model="comments"
          />
          <input-date-picker
            v-else-if="column.key === 'date_published'"
            v-model="date_published"
          />
          <input-number v-else-if="column.key === 'cost'" v-model="cost" />
          <div
            v-else
            v-html="
              column.formatedValue({
                value: rowData[column.key],
                row: rowData[column.key],
              })
            "
          ></div>
        </div>
      </td>
    </template>
  </tr>

  <alert-yes-no
    v-if="isVisibleConfirmAlert"
    title="Error"
    :text="textConfirmAlert"
    :alertResult="alertConfirmResult"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'NewRowPartnerLinksFromPartner',
  mixins: [newRowMixin],

  components: { InputDatePicker, InputSelect, InputNumber },

  data() {
    //let regExpUrl = /^(http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/
    let regExpUrl =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
    return {
      partner_id: {
        value: null,
        isValid: true,
        required: false,
      },

      url: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
        regexp: regExpUrl,
      },
      anchor: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      target_page: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
        regexp: regExpUrl,
      },

      link_status: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },

      company_name: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },

      date_published: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      commercial_pages_link: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: true, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
          {
            id: 'Yes',
            name: 'Yes',
          },
          {
            id: 'No',
            name: 'No',
          },
          // {
          //   id: 'Maybe',
          //   name: 'Maybe',
          // },
        ],
        class: 'sm',
      },

      comments: {
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      cost: {
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      // slack_community: {
      //   value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
      //   isValid: true, // Валидно ли поле
      //   clearable: false, // Показывает значок очистки
      //   required: false, // Обязательность
      //   readonly: false, // Редактируемость
      //   isMultiselect: false, // Возможность множественного выбора
      //   isTags: false, // Показывать в виде тегов
      //   isLiveSearch: true, // Показывать возможность фильтрации по строке
      //   dataList: [
      //     { id: true, name: 'Yes' },
      //     { id: false, name: 'No' },
      //   ],
      //   class: 'sm',
      // },
    }
  },

  async created() {
    console.log('aaa')

    await this.fetchDataLinkStatus()
    await this.fetchDataCompanyNames()
    //    await this.fetchDataSlackCommunityStatus()
    //await this.fetchDataTarget_page()
    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (element && typeof element === 'object' && element.length)
          this.$data[key].value = element
        else if (!element && element !== false) continue
        else if (key === 'link_status') this.$data[key].value = [element]
        else if (key === 'company_name') this.$data[key].value = [element]
        else if (key === 'commercial_pages_link')
          this.$data[key].value = [element]
        else if (key === 'slack_community_status')
          this.$data[key].value = [element]
        else if (key === 'slack_community') this.$data[key].value = [element]
        else this.$data[key].value = element
        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }

    if (
      this.link_status.value &&
      (this.link_status.value.includes('Declined') ||
        this.link_status.value.includes('Recalled') ||
        this.link_status.value.includes('Failed'))
    ) {
      this.comments.required = true
      if (!this.comments.value) this.comments.isValid = false
    } else {
      this.comments.required = false
      this.comments.isValid = true
    }

    if (
      this.link_status.value &&
      this.link_status.value.includes('Undefined')
    ) {
      this.commercial_pages_link.isValid = true
      this.commercial_pages_link.required = false
    } else {
      this.commercial_pages_link.isValid =
        this.commercial_pages_link.value &&
        this.commercial_pages_link.value.length
          ? true
          : false
      this.commercial_pages_link.required = true
    }
  },

  computed: {
    isValid() {
      for (const key in this.$data) {
        let entityValue = this.$data[key]
        if (
          typeof entityValue === 'object' &&
          'isValid' in entityValue &&
          !entityValue.isValid
        )
          return false
      }

      return true
    },
  },

  watch: {
    'link_status.value': {
      deep: true,
      handler() {
        //Монипуляции с урлом нужны чтобы прошла перевалидация урла после смены статуса
        const tmp = this.url.value
        this.url.value = tmp + ' '
        this.$nextTick(() => {
          this.url.value = tmp
        })

        if (
          this.link_status.value &&
          (this.link_status.value.includes('Declined') ||
            this.link_status.value.includes('Recalled') ||
            this.link_status.value.includes('Failed'))
        ) {
          this.comments.required = true
          if (!this.comments.value) this.comments.isValid = false
        } else {
          this.comments.required = false
          this.comments.isValid = true
        }

        if (
          this.link_status.value &&
          this.link_status.value.includes('Undefined')
        ) {
          this.commercial_pages_link.isValid = true
          this.commercial_pages_link.required = false
        } else {
          this.commercial_pages_link.isValid =
            this.commercial_pages_link.value &&
            this.commercial_pages_link.value.length
              ? true
              : false
          this.commercial_pages_link.required = true
        }
      },
    },
  },

  methods: {
    setBoolValue(key, value) {
      this.$data[key].value = value
    },

    async fetchDataLinkStatus() {
      let query = `page=1&per_page=all&link_status=asc`
      let result = await fetchData(urls.linkStatuses, 'GET', null, query)

      this.link_status.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.link_status,
          name: item.link_status,
        }
      })
    },

    async fetchDataCompanyNames() {
      //let query = `page=1&per_page=all&link_status=asc`
      let result = await fetchData(urls.partnersCompanyNames, 'GET', null)

      this.company_name.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.company_name,
          name: item.company_name,
        }
      })
    },

    // async fetchDataSlackCommunityStatus() {
    //   let query = `page=1&per_page=all&link_status=asc`
    //   let result = await fetchData(
    //     urls.slackCommunityStatus,
    //     'GET',
    //     null,
    //     query
    //   )

    //   this.slack_community_status.dataList = (result?.rows || []).map(
    //     (item) => {
    //       return {
    //         id: item.slack_community_status,
    //         name: item.slack_community_status,
    //       }
    //     }
    //   )
    // },

    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.url.isValid || !this.target_page.isValid) {
        this.textConfirmAlert = 'Enter a valid URL.'
        this.isVisibleConfirmAlert = true
        return
      }

      if (!this.isValidUrl('link_status', 'url')) {
        this.textConfirmAlert =
          'The link with status Pitched or Published should have at least 7 symbols after basic URL.'
        this.url.isValid = false
        this.isVisibleConfirmAlert = true
        return
      }

      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
